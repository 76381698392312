import { IQuery, IService } from '../interfaces/index';
import { HTTP_METHODS } from '../common/APIHelper';

const getAll = (query?: IQuery): IService => {
  return { route: '/api/folder', method: HTTP_METHODS.GET, query };
};

const getOneById = (folderId: string): IService => {
  return { route: `/api/folder/${folderId}`, method: HTTP_METHODS.GET };
};

const folderService = {
  getAll,
  getOneById,
};

export default folderService;
