import { IQuery, IService } from '../interfaces/index';
import { HTTP_METHODS } from '../common/APIHelper';
import { IAddHospitalDays, IPatientProgram, IProgramRef } from '../interfaces/Models';

const BASE_PATH = '/api/patient-program';

const add = (payload: IPatientProgram): IService => {
  return { route: BASE_PATH, method: HTTP_METHODS.POST, payload };
};

const getAll = (query?: IQuery): IService => {
  return { route: BASE_PATH, method: HTTP_METHODS.GET, query };
};

const getOne = (id: string): IService => ({
  route: `${BASE_PATH}/${id}`,
  method: HTTP_METHODS.GET,
});

const getScreenshotData = (id: string): IService => ({
  route: `${BASE_PATH}/${id}/screenshot-data`,
  method: HTTP_METHODS.GET,
});

const edit = (id: string, payload: any): IService => {
  return { route: `${BASE_PATH}/${id}`, method: HTTP_METHODS.PUT, payload };
};

const getActiveProgram = (id: string): IService => {
  return { route: `${BASE_PATH}/active/${id}`, method: HTTP_METHODS.GET };
};

export const getAllByPatientId = (patient: string): IService => {
  return { route: `${BASE_PATH}/patient/${patient}`, method: HTTP_METHODS.GET };
};

export const cancelProgramById = (programId: string): IService => {
  return { route: `${BASE_PATH}/cancel-program/${programId}`, method: HTTP_METHODS.PUT };
};

export const hospitalDays = (programId: string, payload: IAddHospitalDays): IService => {
  return {
    route: `${BASE_PATH}/${programId}/hospital-days`,
    method: HTTP_METHODS.PUT,
    payload,
  };
};

const getPatientsReleaseReports = (query?: IQuery): IService => {
  return { route: `${BASE_PATH}/release-reports`, method: HTTP_METHODS.GET, query };
};

const updateGenericData = (id: string, payload: any): IService => {
  return { route: `${BASE_PATH}/update-generic-data/${id}`, method: HTTP_METHODS.PUT, payload };
};

const addProgramReference = (id: string, payload: any): IService => {
  return { route: `${BASE_PATH}/${id}/reference-number/new`, method: HTTP_METHODS.PUT, payload };
};

interface IEditRefData {
  id: string;
  payload: any;
}

const editProgramReference = ({ id, payload }: IEditRefData): IService => {
  return {
    route: `${BASE_PATH}/${id}/reference-number/edit`,
    method: HTTP_METHODS.PUT,
    payload,
  };
};

const deleteProgramReference = ({ id, payload }: IEditRefData): IService => {
  return {
    route: `${BASE_PATH}/${id}/reference-number/delete`,
    method: HTTP_METHODS.PUT,
    payload,
  };
};

export const deleteHospitalDays = (programId: string, hospitalDaysId: string): IService => {
  return {
    route: `${BASE_PATH}/${programId}/hospital-days/${hospitalDaysId}`,
    method: HTTP_METHODS.PUT,
  };
};

const PatientProgramService = {
  add,
  getAll,
  getOne,
  edit,
  updateGenericData,
  getActiveProgram,
  getAllByPatientId,
  cancelProgramById,
  getPatientsReleaseReports,
  hospitalDays,
  deleteHospitalDays,
  getScreenshotData,
  addProgramReference,
  editProgramReference,
  deleteProgramReference,
};

export default PatientProgramService;
