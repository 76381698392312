import { DATE_RANGE_FILTERS } from '../common/Constants';
import { DateRange } from '@mui/lab/DateRangePicker';
import { getThisMonthDate } from './Helper';

interface DateRangeFilterData {
  range?: string;
  from?: string;
  to?: string;
  month?: string;
  year?: string;
}
export const getDateRangeFilterType = ({
  month,
  year,
  range,
  from,
  to,
}: DateRangeFilterData): string => {
  if (range && Object.values(DATE_RANGE_FILTERS).indexOf(range) > -1) {
    return range;
  }
  if (month && year) {
    return DATE_RANGE_FILTERS.SPECIFIC_MONTH;
  }
  if (from && to) {
    return DATE_RANGE_FILTERS.CUSTOM;
  }
  return DATE_RANGE_FILTERS.CURRENT_MONTH;
};

export const getFilterDateRange = ({
  month,
  year,
  range,
  from,
  to,
}: DateRangeFilterData): DateRange<Date> => {
  const currentMonth = getThisMonthDate();

  if (month && year) {
    const monthRange = getMonthRange(Number(month), Number(year));
    return monthRange;
  }
  if (from && to) {
    return [new Date(from), new Date(to)];
  }
  return [currentMonth[0] || new Date(), currentMonth[1] || new Date()];
};

export const getMonthRange = (month: number, year: number): DateRange<Date> => {
  const startDate = new Date(year, month - 1, 1);
  const endDate = new Date(year, month, 0);
  return [startDate, endDate];
};
