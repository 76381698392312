import React, { ReactElement, useRef, useState } from 'react';
import { Icon } from '@iconify/react';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import {
  IClinic,
  IPatient,
  IPatientFlat,
  IPatientProgram,
  IPatientProgramFlat,
  IProgramReleaseReport,
  IUser,
  IUserFlat,
  IVisit,
} from '../interfaces/Models';
import { theme } from '../theme';
import { IAttachment } from '../interfaces/attachment';

export interface IAction<T> {
  name: string;
  handler: (record: T, param1?: string) => void;
  icon: ReactElement;
  actionHandlerParam1?: string;
  isVisible?: boolean;
  variant?: string;
}

interface IMoreOptionsProps<T> {
  record: T;
  actions: IAction<T>[];
}

const MoreOptions = <
  T extends
    | IPatient
    | IClinic
    | IUser
    | IVisit
    | IPatientProgramFlat
    | IPatientFlat
    | IUserFlat
    | IPatientProgram
    | IProgramReleaseReport
    | IAttachment
>({
  record,
  actions,
}: IMoreOptionsProps<T>) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleMenuAction = (action: Function, actionHandlerParam1?: string) => () => {
    action(record, actionHandlerParam1);
    setIsOpen(false);
  };

  const isThereAvailableActions =
    actions.filter((action: IAction<T>) => {
      const { isVisible = true } = action;
      return !!isVisible;
    }).length !== 0;

  return (
    <>
      {isThereAvailableActions ? (
        <>
          <IconButton ref={ref} onClick={() => setIsOpen(true)}>
            <Icon icon={moreVerticalFill} width={20} height={20} />
          </IconButton>
          <Menu
            open={isOpen}
            anchorEl={ref.current}
            onClose={() => setIsOpen(false)}
            PaperProps={{
              sx: { maxWidth: 250 },
            }}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            {actions.map((action) => {
              const {
                isVisible = true,
                handler,
                icon,
                name,
                actionHandlerParam1,
                variant,
              } = action;
              return (
                <div key={`${record._id}_${name}`}>
                  {isVisible && (
                    <MenuItem onClick={handleMenuAction(handler, actionHandlerParam1)}>
                      <ListItemIcon>{icon}</ListItemIcon>
                      <ListItemText
                        primary={name}
                        primaryTypographyProps={{ variant: 'body2' }}
                        sx={{ color: variant || theme.palette.text.primary }}
                      />
                    </MenuItem>
                  )}
                </div>
              );
            })}
          </Menu>
        </>
      ) : null}
    </>
  );
};

export default MoreOptions;
