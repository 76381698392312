/* eslint-disable new-cap */
import { Stack } from '@mui/material';
import React, { useMemo, useState } from 'react';
import FileUpload from './FileUpload';
import { IAttachment } from '../interfaces/attachment';
import attachmentService from '../services/attachemntService';
import FetcherService from '../hooks/FetcherService';
import { IPatient, IPatientProgram, IUser } from '../interfaces/Models';
import { AbilitySubjects } from '../casl/ability';
import { AbilityContext } from '../casl/can';
import { useAbility } from '@casl/react';
import SearchFilter from './SearchFilter';
import { IFolder } from '../interfaces/folder';

export const getPatientEmployeeFullName = (value: IUser | IPatient) => {
  return `${value.personalDetails.firstName.trim()} ${value.personalDetails.lastName.trim()}`;
};

interface IAttachmentsTableHeader {
  patient?: IPatient;
  employee?: IUser;
  program?: IPatientProgram;
  handleTableUpdate: () => void;
  isProfileEmployee?: boolean;
  folder?: IFolder;
  enableSearch?: boolean;
}
export default function AttachmentsTableHeader({
  patient,
  employee,
  program,
  handleTableUpdate,
  isProfileEmployee = false,
  folder,
  enableSearch = false,
}: IAttachmentsTableHeader) {
  const ability = useAbility(AbilityContext);
  const adminAbility = ability.can('manage', AbilitySubjects.ADMIN_ABILITY);
  const [formLoading, setFormLoading] = useState<boolean>(false);

  const handleUploadAttachment = async (val: IAttachment) => {
    await FetcherService<IAttachment>({
      service: attachmentService.add({
        patient,
        employee,
        program,
        folder,
        ...val,
      }),
      setLoading: setFormLoading,
    });
    handleTableUpdate();
  };

  const getFileFullPath = ({
    patient,
    employee,
    program,
    folder,
  }: {
    patient?: IPatient;
    employee?: IUser;
    program?: IPatientProgram;
    folder?: IFolder;
  }) => {
    if (folder) {
      return `${folder.name}`;
    }
    if (employee) {
      return `employees/${getPatientEmployeeFullName(employee)}`;
    }
    if (patient) {
      return `patients/${getPatientEmployeeFullName(patient)}-${patient.idNumber}`;
    }
    if (program) {
      return `program/${String(program._id)}/${program.title}`;
    }
    return 'general';
  };

  const fileFullPath = getFileFullPath({ patient, employee, program, folder });

  const isUploadButtonDisabled = useMemo(() => {
    if (folder) return !adminAbility;
    return !isProfileEmployee && !adminAbility;
  }, [isProfileEmployee, adminAbility]);

  return (
    <Stack
      width="100%"
      direction={'row'}
      justifyContent={enableSearch ? 'space-between' : 'flex-start'}
      alignItems={'center'}
    >
      {enableSearch && <SearchFilter />}
      <FileUpload
        filePath={fileFullPath}
        isLoading={formLoading}
        submitFileHandler={handleUploadAttachment}
        size="medium"
        multiple
        disabled={isUploadButtonDisabled}
      />
    </Stack>
  );
}
