import React from 'react';
import ErrorMessage from '../../../components/ErrorMessage';
import CounterWidgetSkeleton from '../../../components/skeletons/CounterWidgetSkeleton';
import SummaryWidget from '../../../components/SummaryWidget';
import useService from '../../../hooks/HookService';
import useSearchFilter from '../../../hooks/useSearchFilter';
import StatisticsService from '../../../services/StatisticsService';
import { getStartEndDateDep } from '../../../utils/Helper';

interface IProgramStatusCounterWidget {
  title: string;
  color: string;
  icon: string;
  status: string;
}
const ProgramStatusCounterWidget = ({
  title,
  color,
  icon,
  status,
}: IProgramStatusCounterWidget) => {
  const { pageInfo } = useSearchFilter();

  const { from, to, range, month, year } = pageInfo;
  const { isLoading, data, error, errorMessage } = useService<number>({
    service: StatisticsService.getProgramsStatusCount({
      status: status,
      fromDate: getStartEndDateDep(pageInfo),
      toDate: getStartEndDateDep(pageInfo, true),
    }),
    deps: [from, to, range, month, year],
  });

  if (isLoading) {
    return <CounterWidgetSkeleton />;
  }
  if (error) {
    return <ErrorMessage error={errorMessage} />;
  }
  return (
    <SummaryWidget
      route={`/app/programs?status=${status}${range ? `&range=${range}` : ''}${
        from && to ? `&from=${from}&to=${to}` : ''
      }`}
      title={title}
      total={data || 0}
      icon={icon}
      color={color}
    />
  );
};

export default ProgramStatusCounterWidget;
