import React from 'react';
import { Stack, StackProps, Typography } from '@mui/material';

interface props extends StackProps {
  pageTitle: string;
  subTitle: string;
}

const FoldersPageHeader: React.FC<props> = ({ pageTitle, subTitle, sx, ...other }) => {
  return (
    <Stack direction="row" alignItems="center" sx={{ mb: 5, ...sx }} {...other}>
      <Stack flexGrow={1}>
        <Typography variant="h3"> {pageTitle} </Typography>
        <Typography variant="body1" sx={{ color: 'text.disabled', mt: 0.5 }}>
          {subTitle}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default FoldersPageHeader;
