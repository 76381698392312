import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import i18n, { t } from 'i18next';
import { EventClickArg } from '@fullcalendar/core';

export interface IEvents {
  title?: string;
  date?: string;
  color?: string;
  id?: string;
  start?: string;
  end?: string;
  display?: string;
}

export interface CalenderProps {
  events: IEvents[];
  onOpenDate?: (value: Date) => void;
  onOpenEvent?: (value: any) => void;
  startDate?: boolean;
}
const Calender = ({ events, onOpenDate, onOpenEvent, startDate = false }: CalenderProps) => {
  const handleDateClick = (arg: DateClickArg) => {
    if (onOpenDate) {
      onOpenDate(arg.date);
    }
  };

  const handleEventClick = (arg: EventClickArg) => {
    if (onOpenEvent) {
      onOpenEvent(arg);
    }
  };

  return (
    <>
      <FullCalendar
        initialView="dayGridMonth"
        plugins={[dayGridPlugin, interactionPlugin]}
        weekends={true}
        events={events}
        dateClick={handleDateClick}
        eventClick={handleEventClick}
        initialDate={new Date()}
        // initialDate={startDate ? new Date(events[0].date || '') : new Date()}
        locale={i18n.language == 'he' ? 'he' : 'en'}
        direction={i18n.language == 'he' ? 'rtl' : 'ltr'}
        buttonText={{
          today: t('global.calendar.today'),
        }}
      />
    </>
  );
};

export default Calender;
