import React, { useEffect, useMemo } from 'react';
import { styled } from '@mui/system';
import { DateRange } from '@mui/lab/DateRangePicker';
import { useTranslation } from 'react-i18next';
import { FormControl, MenuItem, SelectChangeEvent } from '@mui/material';
import { DATE_RANGE_FILTERS, RESET_PAGE_QUERY, URL_QUERY_PARAMS } from '../common/Constants';
import { SelectFilterInput, FlexDiv, StyledDateIcon } from '../styles/DateRangeFilterStyles';
import DateRangeInput from './DateRangeInput';
import { theme } from '../theme';
import { format } from 'date-fns';
import useSearchFilter from '../hooks/useSearchFilter';
import { getDateRangeFilterType, getFilterDateRange, getMonthRange } from '../utils/dateFilter';
import MonthPicker from './MonthPicker';

const RangeFieldsContainer = styled('div')({
  direction: 'rtl',
});

interface DateRangeFilterProps {
  resetPageAfterFilter?: boolean;
  saveOptionInLocalHost?: boolean;
}
const DateRangeFilter = ({
  resetPageAfterFilter = true,
  saveOptionInLocalHost,
}: DateRangeFilterProps) => {
  const { t } = useTranslation();
  const { pageInfo, changeQueryParams } = useSearchFilter();

  const resetQuery = resetPageAfterFilter ? RESET_PAGE_QUERY : {};

  const [filterType, setFilterType] = React.useState<string>(
    getDateRangeFilterType(pageInfo || {})
  );
  useEffect(() => {
    setFilterType(getDateRangeFilterType(pageInfo || {}));
  }, [pageInfo.range, pageInfo.month, pageInfo.year, pageInfo.from, pageInfo.to]);

  const [newDateRange, setNewDateRange] = React.useState<DateRange<Date>>(
    getFilterDateRange(pageInfo)
  );

  const onChangeCustomRange = (value: DateRange<Date>) => {
    const startDateValue = format(value[0] || new Date(), 'MM-dd-yyyy');
    const endDateValue = format(value[1] || new Date(), 'MM-dd-yyyy');

    changeQueryParams([
      {
        name: URL_QUERY_PARAMS.FROM,
        value: startDateValue,
      },
      {
        name: URL_QUERY_PARAMS.TO,
        value: endDateValue,
      },
      resetQuery,
    ]);
    if (saveOptionInLocalHost) {
      localStorage.setItem(URL_QUERY_PARAMS.FROM, startDateValue);
      localStorage.setItem(URL_QUERY_PARAMS.TO, endDateValue);
    }

    setNewDateRange(value);
  };

  const onChangeCustomMonth = (value: Date | null) => {
    const monthValue = (new Date(value || new Date()).getMonth() + 1).toString();
    const yearValue = new Date(value || new Date()).getFullYear().toString();
    changeQueryParams([
      {
        name: URL_QUERY_PARAMS.MONTH,
        value: monthValue,
      },
      {
        name: URL_QUERY_PARAMS.YEAR,
        value: yearValue,
      },
      resetQuery,
    ]);
    if (saveOptionInLocalHost) {
      localStorage.setItem(URL_QUERY_PARAMS.MONTH, monthValue);
      localStorage.setItem(URL_QUERY_PARAMS.YEAR, yearValue);
    }
    const monthRange = getMonthRange(Number(monthValue), Number(yearValue));

    setNewDateRange(monthRange);
  };

  const handleChangeFilter = (event: SelectChangeEvent) => {
    setFilterType(event.target.value);
    const isCustomFilter = event.target.value == DATE_RANGE_FILTERS.CUSTOM;
    const isCustomMonth = event.target.value == DATE_RANGE_FILTERS.SPECIFIC_MONTH;

    if (!isCustomFilter && !isCustomMonth) {
      const value = event.target.value;
      changeQueryParams(
        [resetQuery, { name: URL_QUERY_PARAMS.RANGE, value }],
        [URL_QUERY_PARAMS.FROM, URL_QUERY_PARAMS.TO, URL_QUERY_PARAMS.MONTH, URL_QUERY_PARAMS.YEAR]
      );
      if (saveOptionInLocalHost) {
        localStorage.setItem(URL_QUERY_PARAMS.RANGE, value);
        localStorage.removeItem(URL_QUERY_PARAMS.FROM);
        localStorage.removeItem(URL_QUERY_PARAMS.TO);
        localStorage.removeItem(URL_QUERY_PARAMS.MONTH);
        localStorage.removeItem(URL_QUERY_PARAMS.YEAR);
      }
    }

    if (isCustomFilter) {
      const startDateValue = format(newDateRange[0] || new Date(), 'MM-dd-yyyy');
      const endDateValue = format(newDateRange[1] || new Date(), 'MM-dd-yyyy');
      changeQueryParams(
        [
          resetQuery,
          {
            name: URL_QUERY_PARAMS.FROM,
            value: startDateValue,
          },
          {
            name: URL_QUERY_PARAMS.TO,
            value: endDateValue,
          },
        ],
        [URL_QUERY_PARAMS.RANGE, URL_QUERY_PARAMS.MONTH, URL_QUERY_PARAMS.YEAR]
      );
      if (saveOptionInLocalHost) {
        localStorage.setItem(URL_QUERY_PARAMS.FROM, startDateValue);
        localStorage.setItem(URL_QUERY_PARAMS.TO, endDateValue);
        localStorage.removeItem(URL_QUERY_PARAMS.RANGE);
        localStorage.removeItem(URL_QUERY_PARAMS.MONTH);
        localStorage.removeItem(URL_QUERY_PARAMS.YEAR);
      }
    }

    if (isCustomMonth) {
      const monthValue = format(newDateRange[0] || new Date(), 'MM');
      const yearValue = format(newDateRange[0] || new Date(), 'yyyy');
      changeQueryParams(
        [
          resetQuery,
          {
            name: URL_QUERY_PARAMS.MONTH,
            value: monthValue,
          },
          {
            name: URL_QUERY_PARAMS.YEAR,
            value: yearValue,
          },
        ],
        [URL_QUERY_PARAMS.RANGE, URL_QUERY_PARAMS.FROM, URL_QUERY_PARAMS.TO]
      );
      if (saveOptionInLocalHost) {
        localStorage.setItem(URL_QUERY_PARAMS.MONTH, monthValue);
        localStorage.setItem(URL_QUERY_PARAMS.YEAR, yearValue);
        localStorage.removeItem(URL_QUERY_PARAMS.RANGE);
        localStorage.removeItem(URL_QUERY_PARAMS.FROM);
        localStorage.removeItem(URL_QUERY_PARAMS.TO);
      }
    }
  };

  const isCustomFilter: boolean = useMemo(
    () => filterType == DATE_RANGE_FILTERS.CUSTOM,
    [filterType]
  );

  const isCustomMonthSelector: boolean = useMemo(
    () => filterType == DATE_RANGE_FILTERS.SPECIFIC_MONTH,
    [filterType]
  );

  const renderValue = () => {
    return (
      <FlexDiv sx={{ justifyContent: isCustomFilter ? 'center' : 'flex-end', direction: 'rtl' }}>
        <FlexDiv>
          <StyledDateIcon icon="akar-icons:clock" />
          {t(`global.calendar.${filterType}`)}
        </FlexDiv>
      </FlexDiv>
    );
  };
  return (
    <FlexDiv
      sx={{
        flexDirection: isCustomFilter || isCustomMonthSelector ? 'column' : 'row',
        float: 'right',
        width: '100%',
      }}
    >
      <FormControl
        sx={{
          width: '100%',
          marginBottom: isCustomFilter ? theme.spacing(2) : 0,
        }}
      >
        <SelectFilterInput
          sx={{ width: '100%' }}
          value={filterType}
          renderValue={renderValue}
          onChange={handleChangeFilter}
        >
          {Object.values(DATE_RANGE_FILTERS).map((filter: string) => {
            return (
              <MenuItem key={filter} value={filter}>
                {t(`global.calendar.${filter}`)}
              </MenuItem>
            );
          })}
        </SelectFilterInput>
      </FormControl>
      {isCustomMonthSelector && (
        <RangeFieldsContainer>
          <MonthPicker
            handleChange={onChangeCustomMonth}
            value={newDateRange[0]}
            label={t('month')}
            sx={{ mt: 2, maxWidth: 160 }}
          />
        </RangeFieldsContainer>
      )}
      {isCustomFilter && (
        <RangeFieldsContainer>
          <DateRangeInput dateRange={newDateRange} onChangeDateRange={onChangeCustomRange} />
        </RangeFieldsContainer>
      )}
    </FlexDiv>
  );
};

export default DateRangeFilter;
