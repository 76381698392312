import React from 'react';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { he, enUS } from 'date-fns/locale';
import i18n from 'i18next';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { SxProps, TextField, Theme } from '@mui/material';
import { DatePicker } from '@mui/lab';

interface IMonthPickerProps {
  label: string;
  handleChange: (date: Date | null) => void;
  value?: Date | null;
  sx?: SxProps<Theme> | undefined;
}
export default function MonthPicker({ label, handleChange, value, sx }: IMonthPickerProps) {
  return (
    <LocalizationProvider locale={i18n.language == 'he' ? he : enUS} dateAdapter={AdapterDateFns}>
      <DatePicker
        onChange={handleChange}
        value={value}
        label={label}
        inputFormat="MM/yyyy" // Set the input format to display only month and year
        views={['year', 'month']} // Restrict the views to only year and month
        openTo="month" // Open the picker directly to the month view
        renderInput={(params: any) => <TextField sx={sx} size="small" label={label} {...params} />}
      />
    </LocalizationProvider>
  );
}
