import { Box, Typography, Stack, Button, IconButton, Tooltip } from '@mui/material';
import Breadcrumbs, { ILink } from './Breadcrumbs';
import { Link, useNavigate } from 'react-router-dom';
import { ReactElement } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { t } from 'i18next';
import { IVisit } from '../interfaces/Models';
import ActionTooltip from './ActionTooltip';

// ----------------------------------------------------------------------
export type MUIColor =
  | 'inherit'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'error'
  | 'info'
  | 'warning'
  | undefined;

export interface IHeadersAction<T> {
  name?: string;
  href?: string;
  icon?: ReactElement;
  color?: MUIColor;
  component?: JSX.Element;
  type: 'Action' | 'Link' | 'goBack' | 'Component' | 'LinkIcon' | 'ActionTooltip';
  onClick?: () => void;
  isVisible?: boolean;
  tooltipTitle?: string;
  record?: T;
}

interface HeaderBreadcrumbsProps<T> {
  links?: Array<ILink>;
  actions?: Array<IHeadersAction<T>>;
  heading?: string;
  sx?: Object;
}

export const HeaderBreadcrumbs = <T extends IVisit>({
  links,
  actions,
  heading,
  sx,
  ...other
}: HeaderBreadcrumbsProps<T>) => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Box sx={{ mb: 5, ...sx }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: { xs: 'flex-start', sm: 'center', md: 'center' },
          justifyContent: 'space-between',
          flexDirection: { xs: 'column', sm: 'row', md: 'row' },
        }}
      >
        {heading && (
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h4" gutterBottom>
              {heading}
            </Typography>
            <Breadcrumbs links={links} {...other} />
          </Box>
        )}

        {actions && (
          <>
            <Stack direction="row" spacing={1}>
              {actions.map((action: IHeadersAction<T>, idx: number) => {
                const {
                  isVisible = true,
                  name,
                  color,
                  type,
                  icon,
                  onClick,
                  tooltipTitle,
                  href,
                  component,
                } = action;
                switch (type) {
                  case 'Action':
                    return (
                      <Button
                        key={`${name}_Action_${idx}`}
                        color={color}
                        disabled={!isVisible}
                        variant="contained"
                        endIcon={icon}
                        onClick={onClick}
                      >
                        {name}
                      </Button>
                    );
                  case 'ActionTooltip':
                    return (
                      <ActionTooltip
                        key={`${name}_Tooltip_${idx}`}
                        disabled={isVisible}
                        title={tooltipTitle || ''}
                      >
                        <IconButton onClick={onClick} color={color} disabled={!isVisible}>
                          {icon}
                        </IconButton>
                      </ActionTooltip>
                    );
                  case 'goBack':
                    return (
                      <Tooltip title={`${t('user.form.goBack')}`} key={`${name}_GoBack_${idx}`}>
                        <IconButton
                          aria-label="go-back"
                          onClick={handleGoBack}
                          key={`${type}_${idx}`}
                        >
                          <ArrowBackIcon />
                        </IconButton>
                      </Tooltip>
                    );
                  case 'LinkIcon':
                    return (
                      <Tooltip title={tooltipTitle || ''} key={`${name}_Link_${idx}`}>
                        <IconButton
                          onClick={() => navigate(href || '')}
                          color={color}
                          disabled={!isVisible}
                        >
                          {action.icon}
                        </IconButton>
                      </Tooltip>
                    );
                  case 'Component':
                    return <div key={`${name}_component_${idx}`}>{component}</div>;
                  default:
                    return (
                      <Button
                        key={`${name} ${idx}`}
                        color={color}
                        disabled={!isVisible}
                        variant="contained"
                        component={Link}
                        endIcon={icon}
                        to={href as string}
                      >
                        {name}
                      </Button>
                    );
                }
              })}
            </Stack>
          </>
        )}
      </Box>
    </Box>
  );
};
export default HeaderBreadcrumbs;
