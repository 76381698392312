import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Page from '../../components/Page';
import { Container } from '@mui/material';
import { IFolder } from '../../interfaces/folder';
import useService from '../../hooks/HookService';
import folderService from '../../services/folderService';
import ErrorMessage from '../../components/ErrorMessage';
import OverlaySpinner from '../../components/OverlaySpinner';
import FoldersList from '../../sections/folders/FoldersList';
import FoldersPageHeader from '../../sections/folders/FoldersPageHeader';

export default function FoldersListPage() {
  const { t } = useTranslation();
  const title = t('drawer.sidebar.folders');

  const { isLoading, data, error, errorMessage } = useService<IFolder[]>({
    service: folderService.getAll({
      page: 0,
      limit: 1000,
    }),
    deps: [],
  });

  const folders = useMemo(() => data || [], [data]);

  const pageSubTitle = useMemo(() => `${folders.length} ${t(title)}`, [folders]);

  if (error) {
    return <ErrorMessage error={errorMessage} />;
  }

  if (isLoading) {
    return <OverlaySpinner />;
  }

  return (
    <Page title={title}>
      <Container>
        <FoldersPageHeader pageTitle={title} subTitle={pageSubTitle} />
        <FoldersList folders={folders} />
      </Container>
    </Page>
  );
}
