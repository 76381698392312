import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import React from 'react';

interface IMedicationSelectField {
  item: any;
  fieldName: string;
  label: string;
  onChangeValue: (index: number, event: SelectChangeEvent<string>) => void;
  index: number;
  options: string[];
  helperText?: string;
}
const MedicationSelectField = ({
  item,
  fieldName,
  label,
  onChangeValue,
  index,
  options,
  helperText,
}: IMedicationSelectField) => {
  return (
    <FormControl
      sx={{
        width: '100%',
      }}
      error={item[`${fieldName}Errors`].length > 1}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        sx={{ width: '100%' }}
        id={`${fieldName}_Filter`}
        disabled={item.added}
        value={item[fieldName]}
        name={fieldName}
        label={label}
        onChange={(e) => onChangeValue(index, e)}
      >
        {options.map((option: string, idx: number) => {
          return (
            <MenuItem key={`${option}_${idx}`} value={option}>
              {option}
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default MedicationSelectField;
