import { useLocation } from 'react-router-dom';
import { IVisitWithProgramTitle } from '../layouts/reports/ReportLayout';
import { IVisit, IVisitFlat } from '../interfaces/Models';

const checkVisitType = (currentVisit: IVisitWithProgramTitle | IVisit | IVisitFlat) => {
  const isFirstReport = currentVisit.isFirst;
  const isRepeatedReport = !currentVisit.isFirst && !currentVisit.isRelease;
  const isReleaseReport = currentVisit.isRelease;
  const isHmpAssessmentReport = currentVisit.visitType.includes('TABLE');
  const isGeneralReport = currentVisit.visitType.includes('GENERAL');

  return {
    isFirstReport,
    isRepeatedReport,
    isHmpAssessmentReport,
    isGeneralReport,
    isReleaseReport,
  };
};

export function checkIfReportTypeRequired(
  currentVisit?: IVisitWithProgramTitle | IVisit | IVisitFlat
) {
  if (!currentVisit) return false;
  const { isFirstReport, isRepeatedReport, isHmpAssessmentReport, isGeneralReport } =
    checkVisitType(currentVisit);

  return (isFirstReport || isRepeatedReport) && !isHmpAssessmentReport && !isGeneralReport;
}

export const checkIfVisitTypeRequireReferenceNumber = (
  currentVisit?: IVisitWithProgramTitle | IVisit | IVisitFlat
) => {
  return checkIfReportTypeRequired(currentVisit);
};

export default function isReportMode(mode: string) {
  const location = useLocation();

  const lastItem = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
  return lastItem == mode;
}
