import React, { useState } from 'react';
import { FormFieldsProps } from '../../../components/Form/FormikForm';
import {
  Button,
  Grid,
  IconButton,
  Typography,
  Autocomplete as MuiAutoComplete,
  SelectChangeEvent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StyledDivider, StyledGrid, StyledTextInput } from '../../../styles/FormFieldsStyles';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import {
  medicationDosages,
  medicationGivingMethod,
  medicinesNames,
} from '../../../common/Medicines';
import { IMedicine } from '../../../interfaces/Models';
import MedicationSelectField from './MedicationSelectField';
import isReportMode from '../../../hooks/isReportMode';
import DiseaseAutoComplete from './DiseaseAutoComplete';

interface ChronicDiseasesFieldsProps extends FormFieldsProps {
  id: string;
  onChangeFormikValue: (field: string, value: any, shouldValidate?: boolean) => void;
  chronicMedicationsValues: Array<IMedicine>;
}
const ChronicDiseasesFields = ({
  touched,
  errors,
  id,
  chronicMedicationsValues,
  onChangeFormikValue,
}: ChronicDiseasesFieldsProps) => {
  const { t } = useTranslation();
  const isViewMode = isReportMode('view');

  const medicineInputState = {
    name: '',
    givingMethod: 'P.O',
    quantity: '',
    quantityUnit: 'MG',
    frequency: '',
    frequencyPeriod: 'day',
    MedicationPeriod: '',
    MedicationPeriodUnit: 'week',
    nameErrors: '',
    givingMethodErrors: '',
    quantityErrors: '',
    quantityUnitErrors: '',
    frequencyErrors: '',
    frequencyPeriodErrors: '',
    MedicationPeriodErrors: '',
    MedicationPeriodUnitErrors: '',
    added: false,
  };

  const [medicines, setMedicines] = useState<IMedicine[]>(
    chronicMedicationsValues ? chronicMedicationsValues : [medicineInputState]
  );

  const prevIsValidMedicine = () => {
    const fieldsEmpty = medicines.filter(
      (medicine: IMedicine) =>
        medicine.name.length == 0 || medicine.frequency.length == 0 || medicine.quantity.length == 0
    );

    if (fieldsEmpty.length > 0) {
      setMedicines((prev) => {
        return prev.map((item: any, i: number) => {
          const medicationFields = Object.keys(medicineInputState);
          medicationFields.forEach((field: string) => {
            if (field.slice(field.length - 6, field.length) != 'Errors') {
              item[`${field}Errors`] = item[field].length > 0 ? '' : t('clinic.form.required');
            }
          });
          return item;
        });
      });
      return false;
    }
    return true;
  };

  const handleAddField = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (prevIsValidMedicine()) {
      const medicinesAfterAddingMedicine = medicines.map((medicine) => {
        return {
          ...medicine,
          added: true,
        };
      });
      setMedicines((prev) => {
        return prev.map((item: IMedicine, i: number) => {
          return { ...item, added: true };
        });
      });
      setMedicines((prev: IMedicine[]) => [...prev, medicineInputState]);
      onChangeFormikValue('data.chronicDiseases.chronicMedications', medicinesAfterAddingMedicine);
    }
  };

  const onChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>,
    newValue?: string | null,
    medicineNameField?: boolean
  ) => {
    setMedicines((prev) => {
      return prev.map((item: any, i: number) => {
        if (i !== index) {
          return item;
        }
        if (medicineNameField) {
          item.name = newValue ? newValue : '';
          item.nameErrors =
            newValue || (newValue && newValue.length > 0) ? '' : t('clinic.form.required');
          return item;
        } else {
          item[event.target.name] = event.target.value;
          item[`${event.target.name}Errors`] =
            event.target.value.length > 0 ? '' : t('clinic.form.required');
          return item;
        }
      });
    });
  };

  const handleRemoveField = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
    const medicinesAfterRemoving = medicines.filter((medicine, idx) => idx != index);
    setMedicines((prev) => prev.filter((item) => item !== prev[index]));
    onChangeFormikValue('data.chronicDiseases.chronicMedications', medicinesAfterRemoving);
  };

  const MedicationTranslationOptions = ['day', 'week', 'month'];

  return (
    <div id={id}>
      <Typography variant="subtitle1" gutterBottom align="left">
        {t('report.chronicDiseases.mainDiagnosis')}
      </Typography>
      <Grid container spacing={{ xs: 0, md: 4, lg: 4 }} id={id + '_Container'}>
        <Grid item xs={12} md={12} lg={12}>
          <DiseaseAutoComplete fieldName="mainDiagnosis" />
        </Grid>
      </Grid>
      <Typography variant="subtitle1" gutterBottom align="left">
        {t('report.chronicDiseases.chronic')}
      </Typography>
      <Grid container spacing={{ xs: 0, md: 4, lg: 4 }} id={id + '_Container'}>
        <Grid item xs={12} md={12} lg={12}>
          <DiseaseAutoComplete fieldName="chronic" />
        </Grid>
      </Grid>
      <div>
        <Typography variant="subtitle1" gutterBottom align="left">
          {t('report.chronicDiseases.medications')}
        </Typography>
        <StyledGrid container justifyContent="left" id={`${id}_Container`}>
          <StyledGrid item xs={12} md={12} lg={12}>
            {medicines.map((item: IMedicine, index: number) => (
              <div
                id={`ChronicMedications__Container`}
                key={`ChronicMedications_${index}`}
                // style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <StyledGrid container spacing={2}>
                  <Grid item xs={12} md={3} lg={3}>
                    <Grid container spacing={1}>
                      <Grid item xs={7.5} md={7.5} lg={7.5}>
                        <MuiAutoComplete
                          value={item.name}
                          onChange={(event: any, newValue: string | null) =>
                            onChange(index, event, newValue, true)
                          }
                          id="Chronic_Medication"
                          options={medicinesNames}
                          sx={{ width: '100%' }}
                          freeSolo
                          renderInput={(params) => (
                            <StyledTextInput
                              {...params}
                              key={`${params.id}_${item.name}_${index}`}
                              disabled={item.added}
                              label={t('report.chronicDiseases.name')}
                              error={item.nameErrors.length > 0}
                              helperText={item.nameErrors ? item.nameErrors : ''}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={4.5} md={4.5} lg={4.5}>
                        <MedicationSelectField
                          fieldName="givingMethod"
                          item={item}
                          label={t('report.chronicDiseases.givingMethod')}
                          onChangeValue={onChange}
                          index={index}
                          options={medicationGivingMethod}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={2.5} lg={2.5}>
                    <Grid container spacing={1}>
                      <Grid item xs={6} md={6} lg={6}>
                        <StyledTextInput
                          fullWidth
                          id={`${id}_TextInput_${index}`}
                          value={item.quantity}
                          name="quantity"
                          label={t('report.chronicDiseases.quantity')}
                          disabled={item.added}
                          onChange={(e) => onChange(index, e)}
                          error={item.quantityErrors.length > 0}
                          helperText={item.quantityErrors ? item.quantityErrors : ''}
                        />
                      </Grid>
                      <Grid item xs={6} md={6} lg={6}>
                        <MedicationSelectField
                          fieldName="quantityUnit"
                          item={item}
                          label={t('report.chronicDiseases.quantityUnit')}
                          onChangeValue={onChange}
                          index={index}
                          options={medicationDosages}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={2.5} lg={2.5}>
                    <Grid container spacing={1}>
                      <Grid item xs={6} md={6} lg={6}>
                        <StyledTextInput
                          fullWidth
                          id={`${id}_TextInput_${index}`}
                          value={item.frequency}
                          name="frequency"
                          label={t('report.chronicDiseases.frequency')}
                          disabled={item.added}
                          onChange={(e) => onChange(index, e)}
                          error={item.frequencyErrors.length > 0}
                          helperText={item.frequencyErrors ? item.frequencyErrors : ''}
                        />
                      </Grid>
                      <Grid item xs={6} md={6} lg={6}>
                        <MedicationSelectField
                          fieldName="frequencyPeriod"
                          item={item}
                          label={t('report.chronicDiseases.frequencyPeriod')}
                          onChangeValue={onChange}
                          index={index}
                          options={MedicationTranslationOptions}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={2.5} lg={2.5}>
                    <Grid container spacing={1}>
                      <Grid item xs={6} md={6} lg={6}>
                        <StyledTextInput
                          fullWidth
                          id={`${id}_TextInput_${index}`}
                          value={item.MedicationPeriod}
                          name="MedicationPeriod"
                          label={t('report.chronicDiseases.MedicationPeriod')}
                          disabled={item.added}
                          onChange={(e) => onChange(index, e)}
                          error={item.MedicationPeriodErrors.length > 0}
                          helperText={
                            item.MedicationPeriodErrors ? item.MedicationPeriodErrors : ''
                          }
                        />
                      </Grid>
                      <Grid item xs={6} md={6} lg={6}>
                        <MedicationSelectField
                          fieldName="MedicationPeriodUnit"
                          item={item}
                          label={t('report.chronicDiseases.MedicationPeriodUnit')}
                          onChangeValue={onChange}
                          index={index}
                          options={MedicationTranslationOptions}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <IconButton
                    color="error"
                    aria-label="delete"
                    onClick={(e) => handleRemoveField(e, index)}
                    size="large"
                    disabled={isViewMode}
                  >
                    <DeleteIcon />
                  </IconButton>
                </StyledGrid>
                <StyledDivider />
              </div>
            ))}

            <Button
              variant="contained"
              disabled={isViewMode}
              endIcon={<AddIcon />}
              onClick={handleAddField}
            >
              {t('global.form.add')}
            </Button>
          </StyledGrid>
        </StyledGrid>
      </div>
    </div>
  );
};

export default ChronicDiseasesFields;
