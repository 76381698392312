import { IVisit } from '../interfaces/Models';
import { EMPLOYEE_POSITIONS, reportTypes } from './Constants';

export const getReportRoute = (visit: IVisit) => {
  let RouteUrl = '';

  switch (visit.visitType) {
    case reportTypes.HMM_ADJUSTMENT_FIRST:
      RouteUrl = 'occupational-therapy/first-house-adjustment';
      break;
    case reportTypes.HMM_ADJUSTMENT_REPEATED:
      RouteUrl = 'occupational-therapy/repeated-house-adjustment';
      break;
    case reportTypes.HMB_GENERAL:
      RouteUrl = 'doctor/general';
      break;
    case reportTypes.HMP_GENERAL:
      RouteUrl = 'physiotherapy/general';
      break;
    case EMPLOYEE_POSITIONS.HMB:
      if (visit.isFirst) {
        RouteUrl = 'doctor/first';
        break;
      } else if (visit.isRelease) {
        RouteUrl = 'doctor/release';
        break;
      }
      RouteUrl = 'doctor/repeated';
      break;
    case EMPLOYEE_POSITIONS.HMK:
      if (visit.isFirst) {
        RouteUrl = 'clinician/first';
        break;
      } else if (visit.isRelease) {
        RouteUrl = 'clinician/release';
        break;
      }
      RouteUrl = 'clinician/repeated';
      break;
    case EMPLOYEE_POSITIONS.HMS:
      if (visit.isFirst) {
        RouteUrl = 'social-worker/first';
        break;
      } else if (visit.isRelease) {
        RouteUrl = 'social-worker/release';
        break;
      }
      RouteUrl = 'social-worker/repeated';
      break;
    case EMPLOYEE_POSITIONS.HMD:
      if (visit.isFirst) {
        RouteUrl = 'dietitian/first';
        break;
      } else if (visit.isRelease) {
        RouteUrl = 'dietitian/release';
        break;
      }
      RouteUrl = 'dietitian/repeated';
      break;
    case EMPLOYEE_POSITIONS.HMM:
      if (visit.isFirst) {
        RouteUrl = 'occupational-therapy/first';
        break;
      } else if (visit.isRelease) {
        RouteUrl = 'occupational-therapy/release';
        break;
      }
      RouteUrl = 'occupational-therapy/repeated';
      break;
    case EMPLOYEE_POSITIONS.HMP:
      if (visit.isFirst) {
        RouteUrl = 'physiotherapy/first';
        break;
      } else if (visit.isRelease) {
        RouteUrl = 'physiotherapy/release';
        break;
      }
      RouteUrl = 'physiotherapy/repeated';
      break;
    case EMPLOYEE_POSITIONS.HMA:
      if (visit.isFirst) {
        RouteUrl = 'nurse/first';
        break;
      } else if (visit.isRelease) {
        RouteUrl = 'nurse/release';
        break;
      }
      RouteUrl = 'nurse/repeated';
      break;
    case reportTypes.HMP_TABLE_FIRST:
      RouteUrl = 'physiotherapy/assessment-form/first';
      break;
    case reportTypes.HMP_TABLE_RELEASE:
      RouteUrl = 'physiotherapy/assessment-form/release';
      break;
  }
  return RouteUrl;
};

export const getReportName = (visit?: IVisit) => {
  let reportName = '';
  if (visit) {
    switch (visit.visitType) {
      case reportTypes.HMB_GENERAL:
        reportName = visit.visitType;
        break;
      case reportTypes.HMP_GENERAL:
        reportName = visit.visitType;
        break;
      case EMPLOYEE_POSITIONS.HMB:
        if (visit.isFirst) {
          reportName = reportTypes.HMB_FIRST;
          break;
        } else if (visit.isRelease) {
          reportName = reportTypes.HMB_RELEASE;
          break;
        }
        reportName = reportTypes.HMB_REPEATED;

        break;
      case EMPLOYEE_POSITIONS.HMK:
        if (visit.isFirst) {
          reportName = reportTypes.HMK_FIRST;
          break;
        } else if (visit.isRelease) {
          reportName = reportTypes.HMK_RELEASE;
          break;
        }
        reportName = reportTypes.HMK_REPEATED;

        break;
      case EMPLOYEE_POSITIONS.HMS:
        if (visit.isFirst) {
          reportName = reportTypes.HMS_FIRST;
          break;
        } else if (visit.isRelease) {
          reportName = reportTypes.HMS_RELEASE;
          break;
        }
        reportName = reportTypes.HMS_REPEATED;

        break;
      case EMPLOYEE_POSITIONS.HMD:
        if (visit.isFirst) {
          reportName = reportTypes.HMD_FIRST;
          break;
        } else if (visit.isRelease) {
          reportName = reportTypes.HMD_RELEASE;
          break;
        }
        reportName = reportTypes.HMD_REPEATED;

        break;
      case EMPLOYEE_POSITIONS.HMM:
        if (visit.isFirst) {
          reportName = reportTypes.HMM_FIRST;
          break;
        } else if (visit.isRelease) {
          reportName = reportTypes.HMM_RELEASE;
          break;
        }
        reportName = reportTypes.HMM_REPEATED;

        break;
      case EMPLOYEE_POSITIONS.HMP:
        if (visit.isFirst) {
          reportName = reportTypes.HMP_FIRST;
          break;
        } else if (visit.isRelease) {
          reportName = reportTypes.HMP_RELEASE;
          break;
        }
        reportName = reportTypes.HMP_REPEATED;

        break;
      case EMPLOYEE_POSITIONS.HMA:
        if (visit.isFirst) {
          reportName = reportTypes.HMA_FIRST;
          break;
        } else if (visit.isRelease) {
          reportName = reportTypes.HMA_RELEASE;
          break;
        }
        reportName = reportTypes.HMA_REPEATED;

        break;
      case reportTypes.HMP_TABLE_FIRST:
        reportName = reportTypes.HMP_TABLE_FIRST;
        break;
      case reportTypes.HMP_TABLE_RELEASE:
        reportName = reportTypes.HMP_TABLE_RELEASE;
        break;
    }
  }
  return reportName;
};
