import { Box, Card, Stack, CardProps, Typography } from '@mui/material';
import { IFolder } from '../../interfaces/folder';
import { useTranslation } from 'react-i18next';
import folderImage from '../../assets/svg/ic_folder.svg';
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

interface Props extends CardProps {
  folder: IFolder;
}

export default function FileFolderCard({ folder, onSelect, sx, ...other }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onOpenFolder = () => {
    navigate(`/app/folder/${folder._id}`);
  };
  return (
    <>
      <Card
        onClick={onOpenFolder}
        sx={{
          p: 2.5,
          width: 1,
          maxWidth: 222,
          boxShadow: 0,
          borderRadius: 2,
          bgcolor: 'background.default',
          border: (theme) => `solid 1px ${theme.palette.divider}`,
          ':hover': {
            cursor: 'pointer',
            borderColor: 'transparent',
            bgcolor: 'background.paper',
            boxShadow: (theme) => theme.customShadows.z20,
          },
          ...sx,
        }}
        {...other}
      >
        <Box component="img" src={folderImage} sx={{ width: 40, height: 40 }} />

        <Typography variant="h6" sx={{ mt: 1, mb: 0.5 }}>
          {folder.name}
        </Typography>

        <Stack
          direction="row"
          alignItems="center"
          spacing={0.75}
          sx={{ typography: 'caption', color: 'text.disabled' }}
        >
          <Box>
            {folder.filesCount} {t('files')}{' '}
          </Box>
        </Stack>
      </Card>
    </>
  );
}
