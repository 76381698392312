import React from 'react';
import { useNavigate } from 'react-router-dom';
import { VISIT_STATUS } from '../../common/Constants';
import { getReportRoute } from '../../common/ReportsCommon';
import Calender from '../../components/Calender';
import { IHospitalDays, IVisit } from '../../interfaces/Models';
import { CalendarCard } from '../../styles/TabsStyles';
import { checkEmployeeReportAuthorization, getProgramVisitsEvents } from '../../utils/Helper';
import { EventClickArg } from '@fullcalendar/core';
import { useTranslation } from 'react-i18next';
import MultiMonthCalendar from '../../components/MultiMonthCalendar';

interface IProgramCalendar {
  visits: IVisit[];
  hospitalDays?: IHospitalDays[];
  handleOpenEditHospitalDaysForm?: (value: IHospitalDays) => void;
  updateAdminAndHmaAbility?: boolean;
  isMonthsScrollable?: boolean;
}
const ProgramCalendar = ({
  visits,
  hospitalDays,
  handleOpenEditHospitalDaysForm,
  updateAdminAndHmaAbility,
  isMonthsScrollable = false,
}: IProgramCalendar) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onHandleVisitOpen = (value: EventClickArg) => {
    const eventId = value.event.id;
    const visitData = visits.find((visit: any) => visit?._id == eventId);
    const hospitalDaysPeriod = hospitalDays?.find((period: IHospitalDays) => period._id == eventId);

    if (hospitalDaysPeriod && handleOpenEditHospitalDaysForm && updateAdminAndHmaAbility) {
      handleOpenEditHospitalDaysForm(hospitalDaysPeriod);
    }
    if (visitData) {
      const BASE_URL = `/app/report/visit/${visitData._id}`;
      const url = getReportRoute(visitData);
      if (visitData.status == VISIT_STATUS.PENDING && checkEmployeeReportAuthorization(visitData)) {
        navigate(`${BASE_URL}/${url}`);
      } else {
        navigate(`${BASE_URL}/${url}/view`);
      }
    }
  };

  const events = getProgramVisitsEvents(t, visits, hospitalDays);
  return (
    <CalendarCard>
      {isMonthsScrollable ? (
        <MultiMonthCalendar
          isMonthsScrollable
          events={events}
          startDate={true}
          onOpenEvent={onHandleVisitOpen}
          monthsViewDivider
        />
      ) : (
        <Calender events={events} startDate={true} onOpenEvent={onHandleVisitOpen} />
      )}
    </CalendarCard>
  );
};

export default ProgramCalendar;
