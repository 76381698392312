import { Box, CardHeader, Skeleton, Stack } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
  RESET_PAGE_QUERY,
  URL_QUERY_PARAMS,
  VISIT_STATUS,
  VISIT_STATUS_TO_HEBREW,
} from '../../common/Constants';
import DateRangeFilter from '../../components/DateRangeFilter';
import DropDownFilter from '../../components/DropDownFilter';
import EmployeesFilter from '../../components/EmployeesFilter';
import FilterList from '../../components/FilterList';
import SearchFilter from '../../components/SearchFilter';
import useSearchFilter from '../../hooks/useSearchFilter';
import { IUserFlat, ReportData } from '../../interfaces/Models';
import { FlexDiv, FlexDiv1, FlexDiv2 } from '../../styles/DateRangeFilterStyles';
import { theme } from '../../theme';
import EmployeeChip from './EmployeeChip';

interface IVisitsTableHeader {
  showStatusFilter: boolean;
  employee?: string;
  showFilters: boolean;
  status?: string;
  children?: React.ReactNode;
}
const VisitsTableHeader = ({
  showStatusFilter,
  employee,
  showFilters,
  status,
  children,
}: IVisitsTableHeader) => {
  const [isDataFetched, setIsDataFetched] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [employees, setEmployees] = useState<IUserFlat[] | undefined>([]);
  const { pageInfo, changeQueryParams, deleteSearchQuery } = useSearchFilter();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const fetchEmployees = async () => {
    if (!isDataFetched && (openModal || pageInfo.employee) && !isLoading) {
      setIsLoading(true);
      try {
        const response = await axios.get(`/api/user`, {
          params: {
            page: 0,
            limit: 1000,
          },
        });
        setIsDataFetched(true);

        setEmployees(response.data.data);
        setIsLoading(false);
      } catch (err: any) {
        setErrorMessage(err.message);
        setError(true);
      }
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, [openModal]);

  const allVisitsStatuses = [...Object.values(VISIT_STATUS), 'MISSING'];

  const handelEmployeeFilterSubmit = (value: ReportData) => {
    const employee = value.employee;
    if (employee) {
      changeQueryParams([
        { name: URL_QUERY_PARAMS.EMPLOYEE, value: employee._id },
        RESET_PAGE_QUERY,
      ]);
    } else {
      deleteSearchQuery([URL_QUERY_PARAMS.EMPLOYEE]);
    }
  };

  const handleDeleteEmployeeFilter = () => {
    changeQueryParams([RESET_PAGE_QUERY], [URL_QUERY_PARAMS.EMPLOYEE]);
  };

  const getSelectedEmployee = () => {
    return employees?.find((employee: IUserFlat) => employee._id == pageInfo.employee);
  };

  const getInitialValues = () => {
    return {
      employee: pageInfo.employee ? getSelectedEmployee() : null,
    };
  };

  return showFilters ? (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      alignItems={'flex-start'}
      justifyContent={'space-between'}
      gap={1}
      width={'100%'}
    >
      <SearchFilter />
      <FlexDiv1 sx={{ alignItems: 'flex-start' }}>
        {!employee && !status ? (
          <FlexDiv2>
            <FilterList
              handelSubmit={handelEmployeeFilterSubmit}
              initialValues={getInitialValues()}
              openModal={openModal}
              handleModalClose={handleModalClose}
              handleOpenModal={handleOpenModal}
              loading={isLoading}
              error={error}
              errorMessage={errorMessage}
            >
              <EmployeesFilter employees={employees} />
            </FilterList>
          </FlexDiv2>
        ) : null}
        <FlexDiv2>
          <DateRangeFilter />
        </FlexDiv2>
        {showStatusFilter && (
          <FlexDiv2>
            <DropDownFilter
              values={allVisitsStatuses}
              keys={Object.values(VISIT_STATUS_TO_HEBREW)}
              defaultValue={VISIT_STATUS.DONE}
              urlParam={URL_QUERY_PARAMS.STATUS}
            />
          </FlexDiv2>
        )}
        {children && <FlexDiv2>{children}</FlexDiv2>}
        {!employee && !status ? (
          <FlexDiv2>
            {isLoading ? (
              <Box flexGrow={1}>
                <Skeleton width={theme.spacing(12)} sx={{ height: theme.spacing(5) }}>
                  <CardHeader />
                </Skeleton>
              </Box>
            ) : (
              <>
                {pageInfo.employee && (
                  <EmployeeChip
                    handleDeleteEmployeeFilter={handleDeleteEmployeeFilter}
                    employeeName={pageInfo.employee ? getSelectedEmployee()?.fullName : undefined}
                  />
                )}
              </>
            )}
          </FlexDiv2>
        ) : null}
      </FlexDiv1>
    </Stack>
  ) : null;
};

export default VisitsTableHeader;
