/* eslint-disable new-cap */
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import FileUpload, { imageFormats } from './FileUpload';
import isReportMode from '../hooks/isReportMode';
import { IAttachment } from '../interfaces/attachment';
import { visitAttachmentsAtom } from '../atoms/visitAttachmentsAtom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import AddLinkIcon from '@mui/icons-material/AddLink';
import { Button } from '@mui/material';
import { theme } from '../theme';
import FetcherService from '../hooks/FetcherService';
import attachmentService from '../services/attachemntService';
import { use } from 'i18next';
import { visitAtom } from '../atoms/visitAtom';
import { reportTypes } from '../common/Constants';

const UploadFabButton = (props: any) => {
  return (
    <Button
      {...props}
      color="secondary"
      sx={{
        backgroundColor: theme.palette.secondary.main,
        '&:hover': {
          backgroundColor: theme.palette.secondary.dark,
        },
      }}
    >
      <AddLinkIcon sx={{ color: 'white' }} />
    </Button>
  );
};

export default function ReportAttachmentButton() {
  const { visitId } = useParams();
  const isViewMode = isReportMode('view');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const currentVisit = useRecoilValue(visitAtom);

  const setVisitAttachments = useSetRecoilState(visitAttachmentsAtom);

  const handleUploadAttachment = async (val: IAttachment) => {
    await FetcherService<IAttachment>({
      service: attachmentService.add({
        visit: visitId,
        ...val,
      }),
      setLoading: setIsLoading,
    });
    setVisitAttachments((prev: IAttachment[]) => [val, ...prev]);
  };

  const isShowUploadAttachmentButton = useMemo(() => {
    if (!currentVisit) return false;
    const isGeneralVisit =
      currentVisit.visitType === reportTypes.HMB_GENERAL ||
      currentVisit.visitType === reportTypes.HMP_GENERAL;

    const isTableVisit =
      currentVisit.visitType === reportTypes.HMP_TABLE_FIRST ||
      currentVisit.visitType === reportTypes.HMP_TABLE_RELEASE;

    return !isGeneralVisit && !isTableVisit;
  }, [currentVisit]);

  return isShowUploadAttachmentButton ? (
    <FileUpload
      filePath={`reports/visit/${visitId}`}
      isLoading={isLoading}
      submitFileHandler={handleUploadAttachment}
      disabled={isViewMode}
      clientAllowedFormats={[...imageFormats, 'pdf']}
      sx={{ width: '100%' }}
      multiple={true}
      UploadButton={UploadFabButton}
    />
  ) : null;
}
