import React, { useState } from 'react';
import { Grid, TextField, IconButton, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { StyledDiv, StyledGrid } from '../../../styles/FormFieldsStyles';
import isReportMode from '../../../hooks/isReportMode';

type IField = {
  fieldValue: string;
  errors: any;
  added: boolean;
};

interface IDynamicFields {
  onChangeFormikValue: (field: string, value: any, shouldValidate?: boolean) => void;
  fieldName: string;
  label: string;
  id: string;
  values: Array<string>;
}

const DynamicFields = ({ onChangeFormikValue, fieldName, label, id, values }: IDynamicFields) => {
  const { t } = useTranslation();
  const isViewMode = isReportMode('view');

  const HabitinputState = {
    fieldValue: '',
    errors: '',
    added: false,
  };

  const getFieldsValues = () => {
    const fieldsValues: IField[] = [];
    values?.map((value: string) =>
      fieldsValues.push({ fieldValue: value, errors: '', added: true })
    );
    return fieldsValues;
  };
  const [formFields, setFormFields] = useState<IField[]>(
    values?.length > 0 ? getFieldsValues() : [HabitinputState]
  );

  const prevIsValid = () => {
    if (formFields.length === 0) {
      return true;
    }

    const someEmpty = formFields.some((item: any) => item.field === '');

    if (someEmpty) {
      formFields.map((item: IField, index: number) => {
        const allPrev: IField[] = [...formFields];

        if (formFields[index].fieldValue == '') {
          allPrev[index].errors = t('clinic.form.required');
        }
        setFormFields(allPrev);
      });
    }

    return !someEmpty;
  };

  const handleAddField = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const addedFields: IField[] = [];
    formFields.map((field) => addedFields.push({ ...field, added: true }));
    if (prevIsValid()) {
      setFormFields((prev: IField[]) => [...addedFields, HabitinputState]);
    }
    onChangeFormikValue(
      fieldName,
      formFields.map((item: IField) => item.fieldValue)
    );
  };

  const onChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormFields((prev) => {
      return prev.map((item: IField, i: number) => {
        if (i !== index) {
          return item;
        }

        item.fieldValue = event.target.value;
        item.errors = event.target.value.length > 0 ? '' : t('clinic.form.required');
        return item;
      });
    });
  };

  const handleRemoveField = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
    setFormFields((prev) => prev.filter((item) => item !== prev[index]));
    onChangeFormikValue(
      fieldName,
      formFields.map((item: IField) => item.fieldValue)
    );
  };

  return (
    <div id={id}>
      <Typography variant="subtitle1" gutterBottom align="left">
        {label}
      </Typography>
      <StyledGrid container justifyContent="left" spacing={4} id={`${id}_Container`}>
        <Grid item xs={12} md={12} lg={12}>
          {formFields.map((item: IField, index: number) => (
            <StyledDiv key={`Sensitivity_${index}`}>
              <TextField
                fullWidth
                id={`${id}_TextInput_${index}`}
                value={item.fieldValue}
                label={label}
                disabled={item.added}
                onChange={(e) => onChange(index, e)}
                error={item.errors.length > 0}
                helperText={item.errors ? item.errors : ''}
              />
              <IconButton
                color="error"
                aria-label="delete"
                onClick={(e) => handleRemoveField(e, index)}
                size="large"
                disabled={isViewMode}
              >
                <DeleteIcon />
              </IconButton>
            </StyledDiv>
          ))}

          <Button
            variant="contained"
            disabled={isViewMode}
            endIcon={<AddIcon />}
            onClick={handleAddField}
          >
            {t('global.form.add')}
          </Button>
        </Grid>
      </StyledGrid>
    </div>
  );
};

export default DynamicFields;
